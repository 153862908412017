import React from "react"

import DatasetDisplay from "../components/DatasetDisplay"

// content specific imports
import DataEuropeanSaaS from "../data/data-european-saas"
import ogImage from "../images/data-points/top-50-plg.png"

// tanstack table
import { createColumnHelper } from "@tanstack/react-table"

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor(row => row["Account Name"], {
    id: "accountName",
    cell: info => (
      <div className="max-w-[100px] truncate">
        <a
          href={info.row.original["domain"]} rel="nofollow"
          target="_blank"
          className="text-blue underline truncate"
        >
          {info.getValue()}
        </a>
      </div>
    ),
    header: "Company",
  }),
  columnHelper.accessor("Company Country"),
  columnHelper.accessor("Founded Year"),
  columnHelper.accessor("pricing (Yes/Blank)", {
    cell: info => (
      <div className="flex gap-1">
        {info.row.original["pricing (Yes/Blank)"] === "Yes" &&
          info.row.original["Self-serve Portal (Yes/Blank)"] === "Yes" && (
            <span className="text-xs bg-soft-tones-amber text-yellow-800 rounded px-1">
              Product Led
            </span>
          )}
        {info.row.original["Enterprise Sales (Yes/Blank)"] === "Yes" &&
          info.row.original["sales"] > 10 && (
            <span className="text-xs bg-soft-tones-green text-green-800 rounded px-1">
              Sales Led
            </span>
          )}
      </div>
    ),
    header: "GTM motion",
  }),
  columnHelper.accessor("pricing (Yes/Blank)", {
    cell: info => (
      <>
        {info.getValue() === "Yes" && (
          <span className="text-xs text-dark-grey bg-light-grey rounded px-1">
            Pricing Page
          </span>
        )}
      </>
    ),
    header: "Pricing Page?",
  }),
  columnHelper.accessor("free (Yes/Blank)", {
    cell: info => (
      <>
        {info.getValue() === "Yes" && (
          <span className="text-xs text-dark-grey bg-light-grey rounded px-1">
            Free Tier
          </span>
        )}
      </>
    ),
    header: "Free Tier?",
  }),
  columnHelper.accessor("Enterprise Sales (Yes/Blank)", {
    cell: info => (
      <>
        {info.getValue() === "Yes" && (
          <span className="text-xs text-dark-grey bg-light-grey rounded px-1">
            Demo Available
          </span>
        )}
      </>
    ),
    header: "Demo?",
  }),
  columnHelper.accessor("growth", {
    cell: info => (
      <>
        {info.getValue() > 5 && (
          <span className="text-xs text-dark-grey bg-light-grey rounded px-1">
            Large growth team
          </span>
        )}
      </>
    ),
    header: "Large growth team?",
  }),
]

const dataContext = {
  title: "Top 50 Product Led Growth (PLG) companies in Europe",
  description:
    "One of our customers used Tactic to build a list of 500+ venture-backed product-led growth companies in Europe. It took them just a few clicks.",
  blurb:
    "One of our customers used Tactic to build a list of venture-backed product-led growth companies (also known as PLG) in Europe. It took them just a few clicks. Here's the top 50. Want the whole list of companies? Leave your email below.",
  ctaText:
    "Get the whole list of 150+ product led growth companies and sync it with your CRM.",
  slug: "top-50-PLG-companies-in-europe",
  freeLimit: 50,
  blogPost: (
    <>
      <p>
        Understanding the SaaS market however is challenging – there is no one
        good definition of SaaS, and within SaaS there are also a few flavors.
      </p>
      <p>
        Compiling data on this market is interesting to B2B companies,
        investment firms, and venture capital companies. Because SaaS companies
        typically copy each other’s operating models and tech stacks, figuring
        out a repeatable motion to sell to SaaS companies is very lucrative. On
        the other hand, they are also one of the fastest growing and highest
        margin business models around, which makes it of interest to investment
        firms and venture capital.
      </p>
      <h2 id="source">Source</h2>
      <p>
        In this analysis, we looked at 3,000 companies that are a) over 100
        people, b) headquartered in Europe, c) founded after 2005 and d) have
        received some sort of funding. The funding will predominantly be
        venture/growth funding, although grants, debt and other types of funding
        is also counted.
      </p>
      <h2 id="method" className="scroll-mt-24">
        What is Software as a Service?
      </h2>
      <p>
        Software as a Service (SaaS) is a software application hosted by a
        third-party provider (the vendor) and made available to customers over
        the internet. SaaS products are usually used through a web browser, and
        the vendor is responsible for maintaining and updating the software, as
        well as hosting and managing the infrastructure needed to run it.
      </p>
      <p>
        Typically, customers pay for SaaS with a monthly or annual subscription,
        and the vendor charges based on the number of users or the level of
        service provided.
      </p>
      <p>
        Examples of include customer relationship management (CRM) systems,
        enterprise resource planning (ERP) systems, and cloud-based productivity
        and collaboration tools.
      </p>
      <p>
        In terms of go-to-market, we divide this into the popular go-to-markets
        in SaaS – product led growth (PLG) and sales led growth (also known as
        ‘enterprise sales’).
      </p>
      <h2 id="what-is-product-led-growth-">What is product led growth?</h2>
      <p>
        Product-led growth (PLG) is a business model and go-to-market strategy
        that uses the product to market itself, acquire, expand, and retain
        customers. The goal is to create a compelling product experience that
        drives organic growth through word-of-mouth and referrals.
      </p>
      <p>
        There are several key characteristics of a product-led growth model:
      </p>
      <ul>
        <li>
          The product is the marketing: free trials, freemium models, or
          self-service onboarding.
        </li>
        <li>
          The product drives acquisition and retention, using concepts like
          viral loops, referral programs, or customer loyalty programs.
        </li>
      </ul>
      <p>
        Concretely, we used Tactic to get answers around the following 3
        questions to understand if there is a PLG model:{" "}
      </p>
      <ol>
        <li>Is there a self service sign in portal?</li>
        <li>Is there a free trial/tier?</li>
        <li>Is there a pricing page on the company website?</li>
        <li>Is there a growth team?</li>
      </ol>

      <h2 id="data" className="scroll-mt-24">
        Data
      </h2>
      <p>
        Here's the first 50 companies in this dataset. If you'd like to ask
        additional questions (e.g. who has monthly pricing?), integrate this
        into your CRM, or access the rest of the dataset, please{" "}
        <a href="#cta">contact us for a chat</a>!
      </p>
    </>
  ),
  columns: columns,
  published: "Dec 1, 2022",
}

const DataSet = ({ location }) => {
  return (
    <DatasetDisplay
      location={location}
      ogImage={ogImage}
      dataset={DataEuropeanSaaS}
      dataContext={dataContext}
    />
  )
}

export default DataSet
