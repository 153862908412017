const DataEuropeanSaaS = [
  {
    "Account Name": "Software",
    Website: "http://www.software.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 560,
    "Company Country": "United Arab Emirates",
    "Founded Year": 2018,
    sales: 78,
    "customer success": 63,
    "account manager": 78,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "GlobalData Plc",
    Website: "http://www.globaldata.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 273,
    "Company Country": "United Kingdom",
    "Founded Year": 2007,
    sales: 54,
    "customer success": 40,
    "account manager": 26,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Adyen",
    Website: "http://www.adyen.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 197,
    "Company Country": "Netherlands",
    "Founded Year": 2006,
    sales: 56,
    "customer success": 11,
    "account manager": 102,
    growth: 8,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Odoo",
    Website: "http://www.odoo.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 187,
    "Company Country": "Belgium",
    "Founded Year": 2005,
    sales: 60,
    "customer success": 86,
    "account manager": 85,
    growth: 3,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Signavio",
    Website: "http://www.signavio.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 186,
    "Company Country": "Germany",
    "Founded Year": 2009,
    sales: 19,
    "customer success": 18,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Klarna",
    Website: "http://www.klarna.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 179,
    "Company Country": "Sweden",
    "Founded Year": 2005,
    sales: 47,
    "customer success": 4,
    "account manager": 5,
    growth: 15,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Trustpilot",
    Website: "http://www.trustpilot.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 170,
    "Company Country": "Denmark",
    "Founded Year": 2007,
    sales: 64,
    "customer success": 70,
    "account manager": "",
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Infinity",
    Website: "http://www.infinity.co",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 162,
    "Company Country": "United Kingdom",
    "Founded Year": 2010,
    sales: 83,
    "customer success": 30,
    "account manager": 87,
    growth: 10,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Socialbakers",
    Website: "http://www.socialbakers.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 160,
    "Company Country": "Czech Republic",
    "Founded Year": 2008,
    sales: 11,
    "customer success": "",
    "account manager": 6,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Personio",
    Website: "http://www.personio.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 159,
    "Company Country": "Germany",
    "Founded Year": 2015,
    sales: 91,
    "customer success": 27,
    "account manager": "",
    growth: 12,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Sendinblue",
    Website: "http://www.sendinblue.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 158,
    "Company Country": "France",
    "Founded Year": 2012,
    sales: 14,
    "customer success": 23,
    "account manager": 5,
    growth: 4,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "The Business of Fashion",
    Website: "http://www.businessoffashion.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 154,
    "Company Country": "United Kingdom",
    "Founded Year": 2007,
    sales: 20,
    "customer success": 1,
    "account manager": 5,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Typeform",
    Website: "http://www.typeform.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 147,
    "Company Country": "Spain",
    "Founded Year": 2012,
    sales: 15,
    "customer success": 10,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "BrowserStack",
    Website: "http://www.browserstack.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 147,
    "Company Country": "Ireland",
    "Founded Year": 2011,
    sales: 74,
    "customer success": 48,
    "account manager": 26,
    growth: 4,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "TravelPerk",
    Website: "http://www.travelperk.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 145,
    "Company Country": "Spain",
    "Founded Year": 2015,
    sales: 64,
    "customer success": 1,
    "account manager": 44,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "PayFit",
    Website: "http://www.payfit.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 143,
    "Company Country": "France",
    "Founded Year": 2015,
    sales: 64,
    "customer success": 48,
    "account manager": 21,
    growth: 11,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Matillion",
    Website: "http://www.matillion.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 143,
    "Company Country": "United Kingdom",
    "Founded Year": 2011,
    sales: 97,
    "customer success": 10,
    "account manager": 4,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "FARFETCH",
    Website: "http://www.farfetch.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 139,
    "Company Country": "United Kingdom",
    "Founded Year": 2008,
    sales: 25,
    "customer success": "",
    "account manager": 36,
    growth: 10,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Spendesk",
    Website: "http://www.spendesk.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 136,
    "Company Country": "France",
    "Founded Year": 2016,
    sales: 49,
    "customer success": 30,
    "account manager": 5,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Showpad",
    Website: "http://www.showpad.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 123,
    "Company Country": "Belgium",
    "Founded Year": 2011,
    sales: 54,
    "customer success": 46,
    "account manager": 2,
    growth: 3,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Aiven",
    Website: "http://www.aiven.io",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 119,
    "Company Country": "Finland",
    "Founded Year": 2016,
    sales: 46,
    "customer success": 15,
    "account manager": 2,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "GoCardless",
    Website: "http://www.gocardless.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 118,
    "Company Country": "United Kingdom",
    "Founded Year": 2011,
    sales: 69,
    "customer success": 27,
    "account manager": 5,
    growth: 3,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Uberall",
    Website: "http://www.uberall.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 115,
    "Company Country": "Germany",
    "Founded Year": 2013,
    sales: 33,
    "customer success": 24,
    "account manager": 12,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Pricefx",
    Website: "http://www.pricefx.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 113,
    "Company Country": "Germany",
    "Founded Year": 2011,
    sales: 22,
    "customer success": 15,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "SumUp",
    Website: "http://www.sumup.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 112,
    "Company Country": "United Kingdom",
    "Founded Year": 2011,
    sales: 93,
    "customer success": 26,
    "account manager": 8,
    growth: 17,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Penta",
    Website: "http://www.getpenta.com",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 112,
    "Company Country": "Germany",
    "Founded Year": 2017,
    sales: 69,
    "customer success": 2,
    "account manager": 28,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "LumApps",
    Website: "http://www.lumapps.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 110,
    "Company Country": "France",
    "Founded Year": 2015,
    sales: 29,
    "customer success": 19,
    "account manager": 8,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "PrestaShop",
    Website: "http://www.prestashop.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 108,
    "Company Country": "France",
    "Founded Year": 2007,
    sales: 11,
    "customer success": "",
    "account manager": 2,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Lodgify",
    Website: "http://www.lodgify.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 108,
    "Company Country": "Spain",
    "Founded Year": 2012,
    sales: 16,
    "customer success": 3,
    "account manager": 2,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Bynder",
    Website: "http://www.bynder.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 106,
    "Company Country": "Netherlands",
    "Founded Year": 2013,
    sales: 37,
    "customer success": 41,
    "account manager": 6,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Segmentify",
    Website: "http://www.segmentify.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 105,
    "Company Country": "United Kingdom",
    "Founded Year": 2015,
    sales: 17,
    "customer success": 3,
    "account manager": 10,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Relex",
    Website: "http://www.relexsolutions.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 105,
    "Company Country": "Finland",
    "Founded Year": 2005,
    sales: 41,
    "customer success": 54,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Snyk",
    Website: "http://www.snyk.io",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 105,
    "Company Country": "United Kingdom",
    "Founded Year": 2015,
    sales: 155,
    "customer success": 20,
    "account manager": 4,
    growth: 3,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Hive",
    Website: "http://www.hive.app",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 104,
    "Company Country": "Germany",
    "Founded Year": 2020,
    sales: 72,
    "customer success": 32,
    "account manager": 53,
    growth: 15,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "SALESmanago",
    Website: "http://www.salesmanago.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 104,
    "Company Country": "Poland",
    "Founded Year": 2011,
    sales: 31,
    "customer success": 2,
    "account manager": 1,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Hopin",
    Website: "http://www.hopin.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 104,
    "Company Country": "United Kingdom",
    "Founded Year": 2019,
    sales: 40,
    "customer success": 15,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "AB Tasty",
    Website: "http://www.abtasty.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 103,
    "Company Country": "France",
    "Founded Year": 2009,
    sales: 25,
    "customer success": 29,
    "account manager": 13,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Storyblok",
    Website: "http://www.storyblok.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 101,
    "Company Country": "Austria",
    "Founded Year": 2017,
    sales: 31,
    "customer success": 4,
    "account manager": "",
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Teamleader",
    Website: "http://www.teamleader.eu",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 101,
    "Company Country": "Belgium",
    "Founded Year": 2012,
    sales: "",
    "customer success": "",
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Platform.sh",
    Website: "http://www.platform.sh",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 100,
    "Company Country": "France",
    "Founded Year": 2010,
    sales: 16,
    "customer success": 14,
    "account manager": 9,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "SimScale",
    Website: "http://www.simscale.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 99,
    "Company Country": "Germany",
    "Founded Year": 2012,
    sales: 10,
    "customer success": 5,
    "account manager": 3,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Egress",
    Website: "http://www.egress.com",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 99,
    "Company Country": "United Kingdom",
    "Founded Year": 2007,
    sales: 45,
    "customer success": 3,
    "account manager": 10,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "CloudFactory",
    Website: "http://www.cloudfactory.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 98,
    "Company Country": "United Kingdom",
    "Founded Year": 2010,
    sales: 21,
    "customer success": 3,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Azimo",
    Website: "http://www.azimo.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 97,
    "Company Country": "United Kingdom",
    "Founded Year": 2012,
    sales: 3,
    "customer success": "",
    "account manager": 4,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Ardoq",
    Website: "http://www.ardoq.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 95,
    "Company Country": "Norway",
    "Founded Year": 2013,
    sales: 24,
    "customer success": 9,
    "account manager": 4,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Livestorm",
    Website: "http://www.livestorm.co",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 95,
    "Company Country": "France",
    "Founded Year": 2016,
    sales: 21,
    "customer success": 7,
    "account manager": 3,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "PlanRadar",
    Website: "http://www.planradar.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 95,
    "Company Country": "Austria",
    "Founded Year": 2013,
    sales: 84,
    "customer success": 10,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Wise",
    Website: "http://www.wise.com",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 94,
    "Company Country": "United Kingdom",
    "Founded Year": 2011,
    sales: 19,
    "customer success": 21,
    "account manager": 19,
    growth: 14,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Brandwatch",
    Website: "http://www.brandwatch.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 94,
    "Company Country": "United Kingdom",
    "Founded Year": 2007,
    sales: 77,
    "customer success": 71,
    "account manager": 27,
    growth: 6,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Prisma",
    Website: "http://www.prisma.io",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 94,
    "Company Country": "Germany",
    "Founded Year": 2016,
    sales: 59,
    "customer success": 5,
    "account manager": 36,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Paddle",
    Website: "http://www.paddle.com",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 91,
    "Company Country": "United Kingdom",
    "Founded Year": 2012,
    sales: 43,
    "customer success": 9,
    "account manager": 1,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Pyramid Analytics",
    Website: "http://www.pyramidanalytics.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 91,
    "Company Country": "Netherlands",
    "Founded Year": 2008,
    sales: 28,
    "customer success": 7,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Rydoo",
    Website: "http://www.rydoo.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 90,
    "Company Country": "Belgium",
    "Founded Year": 2011,
    sales: 20,
    "customer success": 6,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Klaxoon",
    Website: "http://www.klaxoon.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 90,
    "Company Country": "France",
    "Founded Year": 2014,
    sales: 25,
    "customer success": 33,
    "account manager": 34,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Glofox",
    Website: "http://www.glofox.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 89,
    "Company Country": "Ireland",
    "Founded Year": 2014,
    sales: 35,
    "customer success": 13,
    "account manager": 4,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Sonar",
    Website: "http://www.sonarsource.com",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 89,
    "Company Country": "Switzerland",
    "Founded Year": 2008,
    sales: 30,
    "customer success": 7,
    "account manager": 13,
    growth: 4,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Quinyx",
    Website: "http://www.quinyx.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 88,
    "Company Country": "Sweden",
    "Founded Year": 2005,
    sales: 28,
    "customer success": 8,
    "account manager": 2,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Perkbox",
    Website: "http://www.perkbox.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 87,
    "Company Country": "United Kingdom",
    "Founded Year": 2015,
    sales: 43,
    "customer success": 22,
    "account manager": 4,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Detectify",
    Website: "http://www.detectify.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 86,
    "Company Country": "Sweden",
    "Founded Year": 2013,
    sales: 17,
    "customer success": 5,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "MessageBird",
    Website: "http://www.messagebird.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 86,
    "Company Country": "Netherlands",
    "Founded Year": 2011,
    sales: 49,
    "customer success": 18,
    "account manager": 17,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "BigChange",
    Website: "http://www.bigchange.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 86,
    "Company Country": "United Kingdom",
    "Founded Year": 2013,
    sales: 14,
    "customer success": 11,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Tink",
    Website: "http://www.tink.com",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 84,
    "Company Country": "Sweden",
    "Founded Year": 2012,
    sales: 11,
    "customer success": 1,
    "account manager": 16,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "AnyDesk",
    Website: "http://www.anydesk.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 84,
    "Company Country": "Germany",
    "Founded Year": 2014,
    sales: 24,
    "customer success": 8,
    "account manager": 2,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Talkwalker",
    Website: "http://www.talkwalker.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 83,
    "Company Country": "Luxembourg",
    "Founded Year": 2009,
    sales: 73,
    "customer success": 59,
    "account manager": 1,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Payhawk",
    Website: "http://www.payhawk.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 83,
    "Company Country": "United Kingdom",
    "Founded Year": 2018,
    sales: 53,
    "customer success": 9,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "LeadDesk",
    Website: "http://www.leaddesk.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 83,
    "Company Country": "Finland",
    "Founded Year": 2010,
    sales: 10,
    "customer success": 10,
    "account manager": 23,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Agicap",
    Website: "http://www.agicap.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 82,
    "Company Country": "France",
    "Founded Year": 2016,
    sales: 27,
    "customer success": 43,
    "account manager": 20,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Agorapulse",
    Website: "http://www.agorapulse.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 82,
    "Company Country": "France",
    "Founded Year": 2010,
    sales: 6,
    "customer success": 4,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Huboo",
    Website: "http://www.huboo.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 80,
    "Company Country": "United Kingdom",
    "Founded Year": 2019,
    sales: 31,
    "customer success": "",
    "account manager": 5,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "TrueLayer",
    Website: "http://www.truelayer.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 79,
    "Company Country": "United Kingdom",
    "Founded Year": 2016,
    sales: 21,
    "customer success": 7,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Babylon",
    Website: "http://www.babylonhealth.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 79,
    "Company Country": "United Kingdom",
    "Founded Year": 2013,
    sales: 46,
    "customer success": 1,
    "account manager": 17,
    growth: 5,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Whisbi",
    Website: "http://www.whisbi.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 79,
    "Company Country": "Spain",
    "Founded Year": 2008,
    sales: 10,
    "customer success": 10,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Swapcard",
    Website: "http://www.swapcard.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 78,
    "Company Country": "France",
    "Founded Year": 2013,
    sales: 9,
    "customer success": 13,
    "account manager": 8,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "LiveAgent",
    Website: "http://www.liveagent.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 78,
    "Company Country": "Slovakia",
    "Founded Year": 2011,
    sales: 2,
    "customer success": "",
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Keepit",
    Website: "http://www.keepit.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 76,
    "Company Country": "Denmark",
    "Founded Year": 2007,
    sales: 44,
    "customer success": 8,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "GetBusy",
    Website: "http://www.getbusy.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 73,
    "Company Country": "United Kingdom",
    "Founded Year": 1998,
    sales: "",
    "customer success": "",
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Pleo",
    Website: "http://www.pleo.io",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 73,
    "Company Country": "Denmark",
    "Founded Year": 2015,
    sales: 65,
    "customer success": 43,
    "account manager": 9,
    growth: 5,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Pix4D",
    Website: "http://www.pix4d.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 72,
    "Company Country": "Switzerland",
    "Founded Year": 2011,
    sales: 19,
    "customer success": 1,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Sastrify",
    Website: "http://www.sastrify.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 71,
    "Company Country": "Germany",
    "Founded Year": 2020,
    sales: 22,
    "customer success": 10,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Leadfeeder",
    Website: "http://www.leadfeeder.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 71,
    "Company Country": "Finland",
    "Founded Year": 2012,
    sales: 17,
    "customer success": 15,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Memsource",
    Website: "http://www.phrase.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 69,
    "Company Country": "Czech Republic",
    "Founded Year": 2010,
    sales: 5,
    "customer success": 1,
    "account manager": 2,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "GraphCMS",
    Website: "http://www.graphcms.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 69,
    "Company Country": "Germany",
    "Founded Year": 2017,
    sales: 12,
    "customer success": 1,
    "account manager": "",
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Codat",
    Website: "http://www.codat.io",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 68,
    "Company Country": "United Kingdom",
    "Founded Year": 2017,
    sales: 30,
    "customer success": "",
    "account manager": 3,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Monese",
    Website: "http://www.monese.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 68,
    "Company Country": "United Kingdom",
    "Founded Year": 2015,
    sales: 2,
    "customer success": "",
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Amenitiz",
    Website: "http://www.amenitiz.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 67,
    "Company Country": "Spain",
    "Founded Year": 2017,
    sales: 53,
    "customer success": 6,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Virtuagym",
    Website: "http://www.virtuagym.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 67,
    "Company Country": "Netherlands",
    "Founded Year": 2009,
    sales: 16,
    "customer success": 4,
    "account manager": 10,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Nfon",
    Website: "http://www.nfon.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 67,
    "Company Country": "Germany",
    "Founded Year": 2007,
    sales: 25,
    "customer success": "",
    "account manager": 9,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Expensya",
    Website: "http://www.expensya.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 66,
    "Company Country": "France",
    "Founded Year": 2014,
    sales: 20,
    "customer success": 1,
    "account manager": 1,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Userlane",
    Website: "http://www.userlane.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 66,
    "Company Country": "Germany",
    "Founded Year": 2015,
    sales: 14,
    "customer success": 4,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "RELEX Solutions",
    Website: "http://www.relexsolutions.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 66,
    "Company Country": "Finland",
    "Founded Year": 2005,
    sales: 38,
    "customer success": 54,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Smartly.io",
    Website: "http://www.smartly.io",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 66,
    "Company Country": "Finland",
    "Founded Year": 2013,
    sales: 37,
    "customer success": 65,
    "account manager": 2,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Kantox",
    Website: "http://www.kantox.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 65,
    "Company Country": "United Kingdom",
    "Founded Year": 2011,
    sales: 6,
    "customer success": 6,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "WeTransfer",
    Website: "http://www.wetransfer.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 65,
    "Company Country": "Netherlands",
    "Founded Year": 2009,
    sales: 16,
    "customer success": "",
    "account manager": 8,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "FintechOS",
    Website: "http://www.fintechos.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 63,
    "Company Country": "United Kingdom",
    "Founded Year": 2017,
    sales: 18,
    "customer success": 2,
    "account manager": 2,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Peak",
    Website: "http://www.peak.ai",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 63,
    "Company Country": "United Kingdom",
    "Founded Year": 2014,
    sales: 85,
    "customer success": 28,
    "account manager": 68,
    growth: 3,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Tide",
    Website: "http://www.tide.co",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 63,
    "Company Country": "United Kingdom",
    "Founded Year": 2015,
    sales: 73,
    "customer success": 1,
    "account manager": 38,
    growth: 3,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Yousign",
    Website: "http://www.yousign.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 63,
    "Company Country": "France",
    "Founded Year": 2013,
    sales: 22,
    "customer success": 5,
    "account manager": 9,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Sabio",
    Website: "http://www.getsabio.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 63,
    "Company Country": "Germany",
    "Founded Year": 2000,
    sales: 45,
    "customer success": "",
    "account manager": 16,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Soldo",
    Website: "http://www.soldo.com",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 62,
    "Company Country": "United Kingdom",
    "Founded Year": 2014,
    sales: 16,
    "customer success": 9,
    "account manager": 5,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Dixa",
    Website: "http://www.dixa.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 62,
    "Company Country": "Denmark",
    "Founded Year": 2015,
    sales: 27,
    "customer success": 6,
    "account manager": 3,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "PlayPlay",
    Website: "http://www.playplay.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 62,
    "Company Country": "France",
    "Founded Year": 2017,
    sales: 14,
    "customer success": 24,
    "account manager": 2,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Immersive Labs",
    Website: "http://www.immersivelabs.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 61,
    "Company Country": "United Kingdom",
    "Founded Year": 2017,
    sales: 28,
    "customer success": 14,
    "account manager": 15,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Modulr",
    Website: "http://www.modulrfinance.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 61,
    "Company Country": "United Kingdom",
    "Founded Year": 2015,
    sales: 21,
    "customer success": 8,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Flipdish",
    Website: "http://www.flipdish.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 60,
    "Company Country": "Ireland",
    "Founded Year": 2015,
    sales: 24,
    "customer success": 24,
    "account manager": 2,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Beekeeper",
    Website: "http://www.beekeeper.io",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 60,
    "Company Country": "Switzerland",
    "Founded Year": 2012,
    sales: 31,
    "customer success": 17,
    "account manager": 11,
    growth: 4,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Builder.ai",
    Website: "http://www.builder.ai",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 60,
    "Company Country": "United Kingdom",
    "Founded Year": 2012,
    sales: 73,
    "customer success": 2,
    "account manager": "",
    growth: 7,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Usercentrics",
    Website: "http://www.usercentrics.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 59,
    "Company Country": "Germany",
    "Founded Year": 2017,
    sales: 19,
    "customer success": 7,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Veriff",
    Website: "http://www.veriff.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 58,
    "Company Country": "Estonia",
    "Founded Year": 2015,
    sales: 42,
    "customer success": 1,
    "account manager": 7,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Ondato",
    Website: "http://www.ondato.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 58,
    "Company Country": "United Kingdom",
    "Founded Year": 2016,
    sales: 8,
    "customer success": 1,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Dext",
    Website: "http://www.dext.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 58,
    "Company Country": "United Kingdom",
    "Founded Year": 2010,
    sales: 30,
    "customer success": 8,
    "account manager": 27,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "EcoVadis",
    Website: "http://www.ecovadis.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 58,
    "Company Country": "France",
    "Founded Year": 2007,
    sales: 42,
    "customer success": 10,
    "account manager": 39,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Shipup",
    Website: "http://www.shipup.co",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 58,
    "Company Country": "France",
    "Founded Year": 2016,
    sales: 17,
    "customer success": 5,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Bitrise",
    Website: "http://www.bitrise.io",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 58,
    "Company Country": "Hungary",
    "Founded Year": 2015,
    sales: 22,
    "customer success": 7,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Moss",
    Website: "http://www.getmoss.com",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 57,
    "Company Country": "Germany",
    "Founded Year": 2019,
    sales: 9,
    "customer success": 22,
    "account manager": 3,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Juro",
    Website: "http://www.juro.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 57,
    "Company Country": "United Kingdom",
    "Founded Year": 2016,
    sales: 20,
    "customer success": 4,
    "account manager": 2,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Treatwell",
    Website: "http://www.workable.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 57,
    "Company Country": "United Kingdom",
    "Founded Year": 2008,
    sales: 36,
    "customer success": 8,
    "account manager": 35,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Rapyd",
    Website: "http://www.rapyd.net",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 57,
    "Company Country": "United Kingdom",
    "Founded Year": 2016,
    sales: 39,
    "customer success": 3,
    "account manager": 9,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Sendcloud",
    Website: "http://www.sendcloud.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 57,
    "Company Country": "Netherlands",
    "Founded Year": 2012,
    sales: 58,
    "customer success": 22,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Signicat",
    Website: "http://www.signicat.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 56,
    "Company Country": "Norway",
    "Founded Year": 2007,
    sales: 21,
    "customer success": 6,
    "account manager": 4,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Scandit",
    Website: "http://www.scandit.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 56,
    "Company Country": "Switzerland",
    "Founded Year": 2009,
    sales: 63,
    "customer success": 12,
    "account manager": 9,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "October",
    Website: "http://www.october.eu",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 56,
    "Company Country": "France",
    "Founded Year": 2014,
    sales: 22,
    "customer success": "",
    "account manager": 4,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Weaveworks",
    Website: "http://www.weave.works",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 56,
    "Company Country": "United Kingdom",
    "Founded Year": 2014,
    sales: 12,
    "customer success": "",
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Oneflow",
    Website: "http://www.oneflow.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 56,
    "Company Country": "Sweden",
    "Founded Year": 2012,
    sales: 32,
    "customer success": 7,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "ResDiary",
    Website: "http://www.resdiary.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 56,
    "Company Country": "United Kingdom",
    "Founded Year": 2006,
    sales: 20,
    "customer success": 7,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Teamwork",
    Website: "http://www.teamwork.com",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 56,
    "Company Country": "Ireland",
    "Founded Year": 2007,
    sales: 74,
    "customer success": 11,
    "account manager": 27,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "bunq",
    Website: "http://www.bunq.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 56,
    "Company Country": "Netherlands",
    "Founded Year": 2012,
    sales: 3,
    "customer success": 1,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Mews",
    Website: "http://www.mews.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 55,
    "Company Country": "Czech Republic",
    "Founded Year": 2012,
    sales: 41,
    "customer success": 22,
    "account manager": 2,
    growth: 3,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Custify",
    Website: "http://www.custify.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 55,
    "Company Country": "Germany",
    "Founded Year": 2017,
    sales: 4,
    "customer success": 5,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "EMnify",
    Website: "http://www.emnify.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 54,
    "Company Country": "Germany",
    "Founded Year": 2014,
    sales: 17,
    "customer success": 4,
    "account manager": 2,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "OfficeRnD",
    Website: "http://www.officernd.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 54,
    "Company Country": "United Kingdom",
    "Founded Year": 2015,
    sales: 8,
    "customer success": 16,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "SonarSource",
    Website: "http://www.sonarsource.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 54,
    "Company Country": "Switzerland",
    "Founded Year": 2008,
    sales: 22,
    "customer success": 1,
    "account manager": 5,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "BIMobject",
    Website: "http://www.bimobject.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 54,
    "Company Country": "Sweden",
    "Founded Year": 2011,
    sales: 22,
    "customer success": 15,
    "account manager": 3,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Virtuoso",
    Website: "http://www.virtuoso.qa",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 54,
    "Company Country": "United Kingdom",
    "Founded Year": 2016,
    sales: 55,
    "customer success": "",
    "account manager": 29,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Affise",
    Website: "http://www.affise.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 54,
    "Company Country": "Lithuania",
    "Founded Year": 2016,
    sales: 1,
    "customer success": 10,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Pexapark",
    Website: "http://www.pexapark.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 54,
    "Company Country": "Switzerland",
    "Founded Year": 2017,
    sales: 8,
    "customer success": 2,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Freetrade",
    Website: "http://www.freetrade.io",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 53,
    "Company Country": "United Kingdom",
    "Founded Year": 2017,
    sales: 3,
    "customer success": "",
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Adthena",
    Website: "http://www.adthena.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 52,
    "Company Country": "United Kingdom",
    "Founded Year": 2012,
    sales: 19,
    "customer success": 19,
    "account manager": 6,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "iBanFirst",
    Website: "http://www.ibanfirst.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 52,
    "Company Country": "France",
    "Founded Year": 2013,
    sales: 47,
    "customer success": 5,
    "account manager": 14,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Sumsub",
    Website: "http://www.sumsub.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 52,
    "Company Country": "United Kingdom",
    "Founded Year": 2015,
    sales: 16,
    "customer success": 6,
    "account manager": 1,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "ForceManager",
    Website: "http://www.forcemanager.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 52,
    "Company Country": "Spain",
    "Founded Year": 2011,
    sales: 4,
    "customer success": 10,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Proton",
    Website: "http://www.proton.me",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 51,
    "Company Country": "Switzerland",
    "Founded Year": 2014,
    sales: 84,
    "customer success": 4,
    "account manager": 16,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Qonto",
    Website: "http://www.qonto.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 51,
    "Company Country": "France",
    "Founded Year": 2016,
    sales: 17,
    "customer success": 59,
    "account manager": 19,
    growth: 9,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Rossum",
    Website: "http://www.rossum.ai",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 51,
    "Company Country": "United Kingdom",
    "Founded Year": 2017,
    sales: 30,
    "customer success": 5,
    "account manager": 6,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Circula",
    Website: "http://www.circula.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 51,
    "Company Country": "Germany",
    "Founded Year": 2017,
    sales: 14,
    "customer success": 7,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Testbirds",
    Website: "http://www.testbirds.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 51,
    "Company Country": "Germany",
    "Founded Year": 2011,
    sales: 8,
    "customer success": 1,
    "account manager": 5,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Natterbox",
    Website: "http://www.natterbox.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 50,
    "Company Country": "United Kingdom",
    "Founded Year": 2010,
    sales: 12,
    "customer success": 8,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Wattics",
    Website: "http://www.wattics.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 50,
    "Company Country": "Ireland",
    "Founded Year": 2011,
    sales: 1,
    "customer success": 2,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Leapsome",
    Website: "http://www.leapsome.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 49,
    "Company Country": "Germany",
    "Founded Year": 2016,
    sales: 35,
    "customer success": 28,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Veo",
    Website: "http://www.veo.co",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 49,
    "Company Country": "Denmark",
    "Founded Year": 2015,
    sales: 47,
    "customer success": 6,
    "account manager": 4,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "CybSafe",
    Website: "http://www.cybsafe.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 49,
    "Company Country": "United Kingdom",
    "Founded Year": 2015,
    sales: 12,
    "customer success": 4,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "TestGorilla",
    Website: "http://www.testgorilla.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 49,
    "Company Country": "Netherlands",
    "Founded Year": 2019,
    sales: 2,
    "customer success": 1,
    "account manager": "",
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "KNIME",
    Website: "http://www.knime.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 49,
    "Company Country": "Switzerland",
    "Founded Year": 2008,
    sales: 3,
    "customer success": 5,
    "account manager": 9,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "1NCE",
    Website: "http://www.1nce.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 48,
    "Company Country": "Germany",
    "Founded Year": 2017,
    sales: 15,
    "customer success": "",
    "account manager": 6,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Sales Layer",
    Website: "http://www.saleslayer.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 48,
    "Company Country": "Spain",
    "Founded Year": 2013,
    sales: 12,
    "customer success": 8,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Gearset",
    Website: "http://www.gearset.com",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 47,
    "Company Country": "United Kingdom",
    "Founded Year": 2015,
    sales: 32,
    "customer success": 20,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Whatagraph",
    Website: "http://www.whatagraph.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 47,
    "Company Country": "Netherlands",
    "Founded Year": 2015,
    sales: 1,
    "customer success": 8,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "SIX",
    Website: "http://www.six-group.com",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 47,
    "Company Country": "Switzerland",
    "Founded Year": 2008,
    sales: 83,
    "customer success": 10,
    "account manager": 50,
    growth: 14,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "birdie",
    Website: "http://www.birdie.care",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 47,
    "Company Country": "United Kingdom",
    "Founded Year": 2017,
    sales: 38,
    "customer success": 7,
    "account manager": 2,
    growth: 4,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Horizon Discovery",
    Website: "http://www.horizondiscovery.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 46,
    "Company Country": "United Kingdom",
    "Founded Year": 2005,
    sales: 13,
    "customer success": "",
    "account manager": 3,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Ziflow",
    Website: "http://www.ziflow.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 46,
    "Company Country": "United Kingdom",
    "Founded Year": 2016,
    sales: 15,
    "customer success": 2,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Linkfire",
    Website: "http://www.linkfire.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 46,
    "Company Country": "Denmark",
    "Founded Year": 2014,
    sales: 4,
    "customer success": 2,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "SeedLegals",
    Website: "http://www.seedlegals.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 45,
    "Company Country": "United Kingdom",
    "Founded Year": 2016,
    sales: 9,
    "customer success": 1,
    "account manager": 4,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "simpleshow",
    Website: "http://www.simpleshow.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 45,
    "Company Country": "Luxembourg",
    "Founded Year": 2008,
    sales: 30,
    "customer success": 12,
    "account manager": 12,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Speexx",
    Website: "http://www.speexx.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 45,
    "Company Country": "Germany",
    "Founded Year": 2011,
    sales: 25,
    "customer success": 28,
    "account manager": 25,
    growth: 3,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Aptem",
    Website: "http://www.aptem.co.uk",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 45,
    "Company Country": "United Kingdom",
    "Founded Year": 2013,
    sales: 4,
    "customer success": 6,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Blinkist",
    Website: "http://www.blinkist.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 44,
    "Company Country": "Germany",
    "Founded Year": 2012,
    sales: 5,
    "customer success": "",
    "account manager": "",
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Perlego",
    Website: "http://www.perlego.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 44,
    "Company Country": "United Kingdom",
    "Founded Year": 2017,
    sales: 3,
    "customer success": "",
    "account manager": "",
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Rentals United",
    Website: "http://www.rentalsunited.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 44,
    "Company Country": "Spain",
    "Founded Year": 2015,
    sales: 53,
    "customer success": "",
    "account manager": 95,
    growth: 2,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Lunar",
    Website: "http://www.lunar.app",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 43,
    "Company Country": "Denmark",
    "Founded Year": 2015,
    sales: 58,
    "customer success": 1,
    "account manager": 14,
    growth: 19,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Trint",
    Website: "http://www.trint.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 43,
    "Company Country": "United Kingdom",
    "Founded Year": 2014,
    sales: 12,
    "customer success": 3,
    "account manager": 2,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Channable",
    Website: "http://www.channable.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 43,
    "Company Country": "Netherlands",
    "Founded Year": 2014,
    sales: 16,
    "customer success": 11,
    "account manager": 1,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Funnel",
    Website: "http://www.funnel.io",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 42,
    "Company Country": "Sweden",
    "Founded Year": 2014,
    sales: 28,
    "customer success": 33,
    "account manager": 21,
    growth: 12,
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Amity",
    Website: "http://www.amity.co",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 42,
    "Company Country": "United Kingdom",
    "Founded Year": 2012,
    sales: 53,
    "customer success": 2,
    "account manager": 26,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Feedier",
    Website: "http://www.feedier.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 42,
    "Company Country": "France",
    "Founded Year": 2018,
    sales: 1,
    "customer success": "",
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Betty Blocks",
    Website: "http://www.bettyblocks.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 42,
    "Company Country": "Netherlands",
    "Founded Year": 2016,
    sales: 8,
    "customer success": 7,
    "account manager": 2,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Tresorit",
    Website: "http://www.tresorit.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 42,
    "Company Country": "Switzerland",
    "Founded Year": 2011,
    sales: 7,
    "customer success": 2,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Alma",
    Website: "http://www.almapay.com",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 42,
    "Company Country": "France",
    "Founded Year": 2017,
    sales: 42,
    "customer success": 17,
    "account manager": 20,
    growth: 28,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "nShift",
    Website: "http://www.unifaun.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 40,
    "Company Country": "Sweden",
    "Founded Year": 1996,
    sales: 28,
    "customer success": 5,
    "account manager": 9,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Darwinex",
    Website: "http://www.darwinex.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 40,
    "Company Country": "United Kingdom",
    "Founded Year": 2012,
    sales: 2,
    "customer success": "",
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Klevu",
    Website: "http://www.klevu.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 40,
    "Company Country": "Finland",
    "Founded Year": 2013,
    sales: 12,
    "customer success": 4,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Synthesia",
    Website: "http://www.synthesia.io",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 39,
    "Company Country": "United Kingdom",
    "Founded Year": 2017,
    sales: 20,
    "customer success": 6,
    "account manager": 1,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Scrive",
    Website: "http://www.scrive.com",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 39,
    "Company Country": "Sweden",
    "Founded Year": 2010,
    sales: 37,
    "customer success": 15,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "movingimage",
    Website: "http://www.movingimage.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 39,
    "Company Country": "Germany",
    "Founded Year": 2009,
    sales: 5,
    "customer success": 3,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Happeo",
    Website: "http://www.happeo.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 39,
    "Company Country": "Finland",
    "Founded Year": 2017,
    sales: 11,
    "customer success": 7,
    "account manager": 1,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Stigg",
    Website: "http://www.stigg.io",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 38,
    "Company Country": "Israel",
    "Founded Year": "",
    sales: "",
    "customer success": "",
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "WeGift",
    Website: "http://www.wegift.io",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 38,
    "Company Country": "United Kingdom",
    "Founded Year": 2016,
    sales: 7,
    "customer success": 7,
    "account manager": 3,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Juni",
    Website: "http://www.juni.co",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 37,
    "Company Country": "Sweden",
    "Founded Year": 2020,
    sales: 21,
    "customer success": 6,
    "account manager": 2,
    growth: 3,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Contractbook",
    Website: "http://www.contractbook.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 37,
    "Company Country": "Denmark",
    "Founded Year": 2017,
    sales: 11,
    "customer success": 5,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "WorkMotion",
    Website: "http://www.workmotion.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 37,
    "Company Country": "Germany",
    "Founded Year": 2020,
    sales: 42,
    "customer success": 12,
    "account manager": 1,
    growth: 1,
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "NAGA",
    Website: "http://www.naga.com",
    "Companies House Number": "",
    "Generic Name": "Yes",
    "# Keyword Results": 37,
    "Company Country": "Germany",
    "Founded Year": 2016,
    sales: 28,
    "customer success": "",
    "account manager": 14,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "GitGuardian",
    Website: "http://www.gitguardian.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 36,
    "Company Country": "France",
    "Founded Year": 2017,
    sales: 9,
    "customer success": "",
    "account manager": 3,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "MyWorkpapers",
    Website: "http://www.myworkpapers.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 36,
    "Company Country": "United Kingdom",
    "Founded Year": 2009,
    sales: 4,
    "customer success": 1,
    "account manager": "",
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Zivver",
    Website: "http://www.zivver.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 36,
    "Company Country": "Netherlands",
    "Founded Year": 2015,
    sales: 10,
    "customer success": 8,
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "TextMaster",
    Website: "http://www.textmaster.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 36,
    "Company Country": "France",
    "Founded Year": 2011,
    sales: 13,
    "customer success": "",
    "account manager": 1,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Leapwork",
    Website: "http://www.leapwork.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 35,
    "Company Country": "Denmark",
    "Founded Year": 2015,
    sales: 36,
    "customer success": 7,
    "account manager": 5,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "NavVis",
    Website: "http://www.navvis.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 35,
    "Company Country": "Germany",
    "Founded Year": 2013,
    sales: 25,
    "customer success": 12,
    "account manager": 5,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "",
  },
  {
    "Account Name": "Tines",
    Website: "http://www.tines.com",
    "Companies House Number": "",
    "Generic Name": "",
    "# Keyword Results": 34,
    "Company Country": "Ireland",
    "Founded Year": 2018,
    sales: 38,
    "customer success": 12,
    "account manager": 2,
    growth: "",
    "Enterprise Sales (Yes/Blank)": "Yes",
    "PLG (Yes/Blank)": "Yes",
    "Self-serve Portal (Yes/Blank)": "Yes",
    "pricing (Yes/Blank)": "Yes",
    "free (Yes/Blank)": "Yes",
  },
]

export default DataEuropeanSaaS
